/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-05-03 03:35:25
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-05-28 16:08:49
 * @FilePath: /mbti-web/app/hooks/useRouter.js
 * @Description: useRouter 二次封装
 */
import useSearchReset from '@/hooks/useSearchReset';
import { useRouter } from 'next-nprogress-bar';

const useMyRouter = () => {
  const router = useRouter();
  const searchReset = useSearchReset();

  return {
    ...router,
    push: (href) => {
      router.push(href);
      searchReset();
    },
  };
};

export default useMyRouter;
