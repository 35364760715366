/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-04-07 18:44:37
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-04-27 03:43:38
 * @FilePath: /mbti-web/app/icons/index.js
 * @Description:
 */
import Clear from '@/icons/clear.svg';
import Down from '@/icons/down.svg';
import Intro from '@/icons/intro.svg';
import More from '@/icons/more.svg';
import Right from '@/icons/right.svg';
import Search from '@/icons/search.svg';
import Vote from '@/icons/vote.svg';
import Voting from '@/icons/voting.svg';

const SearchIcon = () => <Search />;
const VoteIcon = () => <Vote />;
const RightIcon = () => <Right />;
const MoreIcon = () => <More />;
const ClearIcon = () => <Clear />;
const VotingIcon = () => <Voting />;
const DownIcon = () => <Down />;
const IntroIcon = () => <Intro />;

export {
  ClearIcon,
  DownIcon,
  IntroIcon,
  MoreIcon,
  RightIcon,
  SearchIcon,
  VoteIcon,
  VotingIcon,
};
