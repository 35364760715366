/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-04-17 01:01:14
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-05-28 16:14:19
 * @FilePath: /mbti-web/app/utils/isImageURL.js
 * @Description:
 */
const isImageURL = (url) => {
  if (!url) return false;
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp']; // 常见的图片后缀名

  const extension = url.substring(url.lastIndexOf('.')).toLowerCase(); // 获取URL的后缀名并转换为小写

  return imageExtensions.includes(extension); // 判断后缀名是否在图片后缀数组中
};

export default isImageURL;
