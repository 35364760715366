/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-02-27 19:28:21
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-06-07 11:35:22
 * @FilePath: /mbti-web/image-loader.js
 * @Description:
 */
export default function myImageLoader({ src, width, quality }) {
  return `${src}?w=${width}&q=${quality || 75}`;
}

