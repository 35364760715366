/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-05-03 03:24:39
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-05-28 16:08:44
 * @FilePath: /mbti-web/app/hooks/useSearchReset.js
 * @Description:搜索状态重置
 */

import {
  iselWorkIdAtom,
  itemListAtom,
  itemListCurrentAtom,
  itemListTotalAtom,
  nameAtom,
  openAtom,
  tabKeyAtom,
  tierAtom,
  towItemListAtom,
  towItemListCurrentAtom,
  towItemListTotalAtom,
  workListAtom,
  workListCurrentAtom,
  workListTotalAtom,
} from '@/models/search';

import { useResetAtom } from 'jotai/utils';

const useSearchReset = () => {
  const resetName = useResetAtom(nameAtom); // 当前输入框内容
  const resetOpen = useResetAtom(openAtom); // 是否展开下拉菜单
  const resetTabKey = useResetAtom(tabKeyAtom); // 当前tab选项
  const resetTier = useResetAtom(tierAtom); // 当前层级
  const resetWorkList = useResetAtom(workListAtom); // 作品列表
  const resetWorkListCurrent = useResetAtom(workListCurrentAtom); // 作品列表当前页
  const resetWorkListTotal = useResetAtom(workListTotalAtom); // 作品列表总数
  const resetItemList = useResetAtom(itemListAtom); // 人物列表
  const resetItemListCurrent = useResetAtom(itemListCurrentAtom); // 人物列表当前页
  const resetItemListTotal = useResetAtom(itemListTotalAtom); // 人物列表总数
  const resetTowItemList = useResetAtom(towItemListAtom); // 第二层人物列表
  const resetTowItemListCurrent = useResetAtom(towItemListCurrentAtom); // 第二层人物列表当前页
  const resetTowItemListTotal = useResetAtom(towItemListTotalAtom); // 第二层人物列表总数
  const resetWorkId = useResetAtom(iselWorkIdAtom); // 选中的作品id

  return () => {
    resetName();
    resetOpen();
    resetTabKey();
    resetTier();
    resetWorkList();
    resetWorkListCurrent();
    resetWorkListTotal();
    resetItemList();
    resetItemListCurrent();
    resetItemListTotal();
    resetTowItemList();
    resetTowItemListCurrent();
    resetTowItemListTotal();
    resetWorkId();
  };
};

export default useSearchReset;
