import Calculator from '@/img/home/calculator.png';
import Octuple from '@/img/home/octuple.png';
import Relation from '@/img/home/relation.png';
import Test from '@/img/home/test.png';
import Wiki from '@/img/home/wiki.png';

const MBTI_OCTUPLES = [
  { label: '全部', value: '' },
  { label: 'ESTP', value: 'ESTP' },
  { label: 'ESFP', value: 'ESFP' },
  { label: 'ISTJ', value: 'ISTJ' },
  { label: 'ISFJ', value: 'ISFJ' },
  { label: 'ENTP', value: 'ENTP' },
  { label: 'ENFP', value: 'ENFP' },
  { label: 'INTJ', value: 'INTJ' },
  { label: 'INFJ', value: 'INFJ' },
  { label: 'ESTJ', value: 'ESTJ' },
  { label: 'ENTJ', value: 'ENTJ' },
  { label: 'ISTP', value: 'ISTP' },
  { label: 'INTP', value: 'INTP' },
  { label: 'ESFJ', value: 'ESFJ' },
  { label: 'ENFJ', value: 'ENFJ' },
  { label: 'ISFP', value: 'ISFP' },
  { label: 'INFP', value: 'INFP' },
];

const MBTI_OCTUPLE_LIST = [
  'ESTP',
  'ESFP',
  'ISTJ',
  'ISFJ',
  'ENTP',
  'ENFP',
  'INTJ',
  'INFJ',
  'ESTJ',
  'ENTJ',
  'ISTP',
  'INTP',
  'ESFJ',
  'ENFJ',
  'ISFP',
  'INFP',
];

const MBTI_OCTUPLE_MAP = {
  ESTP: ['Se', 'Ti', 'Fe', 'Ni', 'Si', 'Te', 'Fi', 'Ne'],
  ESFP: ['Se', 'Fi', 'Te', 'Ni', 'Si', 'Fe', 'Ti', 'Ne'],
  ISTJ: ['Si', 'Te', 'Fi', 'Ne', 'Se', 'Ti', 'Fe', 'Ni'],
  ISFJ: ['Si', 'Fe', 'Ti', 'Ne', 'Se', 'Fi', 'Te', 'Ni'],
  ENTP: ['Ne', 'Ti', 'Fe', 'Si', 'Ni', 'Te', 'Fi', 'Se'],
  ENFP: ['Ne', 'Fi', 'Te', 'Si', 'Ni', 'Fe', 'Ti', 'Se'],
  INTJ: ['Ni', 'Te', 'Fi', 'Se', 'Ne', 'Ti', 'Fe', 'Si'],
  INFJ: ['Ni', 'Fe', 'Ti', 'Se', 'Ne', 'Fi', 'Te', 'Si'],
  ESTJ: ['Te', 'Si', 'Ne', 'Fi', 'Ti', 'Se', 'Ni', 'Fe'],
  ENTJ: ['Te', 'Ni', 'Se', 'Fi', 'Ti', 'Ne', 'Si', 'Fe'],
  ISTP: ['Ti', 'Se', 'Ni', 'Fe', 'Te', 'Si', 'Ne', 'Fi'],
  INTP: ['Ti', 'Ne', 'Si', 'Fe', 'Te', 'Ni', 'Se', 'Fi'],
  ESFJ: ['Fe', 'Si', 'Ne', 'Ti', 'Fi', 'Se', 'Ni', 'Te'],
  ENFJ: ['Fe', 'Ni', 'Se', 'Ti', 'Fi', 'Ne', 'Si', 'Te'],
  ISFP: ['Fi', 'Se', 'Ni', 'Te', 'Fe', 'Si', 'Ne', 'Ti'],
  INFP: ['Fi', 'Ne', 'Si', 'Te', 'Fe', 'Ni', 'Se', 'Ti'],
};

const MBTI_ROLE_LIST = [
  {
    name: '主角',
    key: 'Dominant',
    title: '1位主角丨 Dominant',
    firstLine:
      '通常是我们在童年期即已发展出的功能。我们会倾向于首先运用它，指望它来解决我们的问题、帮助我们成功。',
    secondLine:
      '这是我们最值得信赖和最常使用的功能，通常是相当成熟完善的。尽管多数情况下我们可以毫不费力、无意识、自然而然地运用它，但我们也可以更有意识地去掌控它，让它更稳定的发挥。',
    thirdLine:
      '运用主导功能所需投入的精力极少。正如电影中的主角所起的作用那样，主导功能的运用能够令我们摆脱困境。然而，有时我们也会过分依赖主导功能，变得过于傲慢自大。此时它主要起消极作用。',
  },
  {
    name: '配角',
    key: 'Auxiliary',
    title: '2位配角丨Auxiliary',
    firstLine:
      '配角功能是我们第二常用的功能，通常带有主动性和工具性，它辅助我们的1位功能。',
    secondLine:
      '当状况良好时，配角功能会在青年时期得到很好的发展。若状况不佳，则可能是主角过于强大而使得配角不能很好地发展，此时配角功能很难辅助主角功能得到更好的效果。',
    thirdLine:
      '当1位功能内倾时，外倾的配角功能是我们与外界打交道的方式，会是活跃而易于被觉察的。此时区分主副最好的方法是：运用时是主动性、工具性的，还是潜意识的。 ',
  },
  {
    name: '替补',
    key: 'Tertiary',
    title: '3位替补丨Tertiary',
    firstLine:
      '替补为我们提供了恢复自我的途径。它是2位的替补，并经常与2位共同协作。',
    secondLine:
      '在我们年轻时，若不是情非得已，配角不能很好地完成任务，可能并不会有力地运用它。通常，在成人初期我们会被替补的活动所吸引。第三功能通常是我们发挥创造力的途径，会在我们像孩童般嬉戏时出现。',
    thirdLine:
      '在最糟的情况下，这是我们表现出幼稚行为时运用的功能。此时它稳定性不佳，会令我们自己与其他人偏离常轨。',
  },
  {
    name: '龙套',
    key: 'Inferior',
    title: '4位龙套丨Inferior',
    firstLine:
      '龙套演员通常在我们中年以前并未发展。我们通常是先遇到它在跟我们捣乱，作为我们的内心的命令、恐惧及其他负面情绪出现。',
    secondLine:
      '此类恐惧是劣势功能在发挥作用的体现，而我们常常在此时并不会成熟地去对待它。在龙套演员登场时，我们常常要投入相当多的精力——即使是在我们掌握了调配它的技能以后。',
    thirdLine:
      '一旦我们学会去信赖并发展它，龙套演员能够成为平衡我们生活的桥梁，连通我们的目标、灵感、理想。',
  },
  {
    name: '对手',
    key: 'The Opposing Role',
    title: '5位对手丨The Opposing Role',
    firstLine:
      '对手通常是让我们感到棘手的——无论何事发生，都拒绝“加入”我方阵营。',
    secondLine:
      '我们或许可以通过有意让它上场来发展它，但通常可能并不会给它太多的戏份，要让它发挥自如也得投入我们更多的精力。',
    thirdLine:
      '从积极方面来讲，对手是主角的影子，它的存在是对主角的丰富，帮助我们更持久地追求目标。 ',
  },
  {
    name: '批评',
    key: 'The Critical Parent Role',
    title: '6位批评家丨The Critical Parent Role',
    firstLine:
      '批评家使我们能够找出弱点，打压别人。当别人运用它来对付我们时，我们就知道它是怎么起作用的了。',
    secondLine: '它常常在我们遭遇重大危机、顶着极大压力时，才登台表演。',
    thirdLine:
      '若我们愿意，可以让它继续留在台上。若要发挥它的积极作用，我们必须学会欣赏它，留一席之地给它。如此，它会拥有神奇的品质，带给我们深刻的智慧洞见。',
  },
  {
    name: '盲点',
    key: 'The Deceiving Role',
    title: '7位盲点丨The Deceiving Role',
    firstLine: '盲点是我们很难感知到的功能。',
    secondLine:
      '它通常会愚弄我们，若我们强行使用/将它当真，则会形成错觉、表现出滑稽的行为。此时我们会发现自己陷入两难境地，左右皆非。',
    thirdLine:
      '尽管如此，它也有积极面，能以滑稽来助我们减压，教我们自嘲。如此，它可以为我们服务，在我们恢复精力时发挥作用。',
  },
  {
    name: '魔鬼',
    key: 'The Devilish Role',
    title: '8位魔鬼丨The Devilish Role',
    firstLine: '魔鬼通常不干好事。当它出现时，可能会让我们自毁毁人。',
    secondLine:
      '受它影响作出的行动（或不作为）往往会令我们事后悔恨。通常，我们并不知道如何有意使用它，感觉它多半是在我们毫无觉察的情况下突然爆发的。',
    thirdLine:
      '若我们心胸够宽广，魔鬼也可以改变阵营，倒戈投诚。这时它会成为创造性的力量，带来新生。',
  },
];

const MBTI_ROLE_COLOR_MAP = {
  主角: '#65D0A9',
  配角: '#65D0A9',
  替补: '#333333',
  龙套: '#333333',
  对手: '#999999',
  批评: '#999999',
  盲点: '#E3685B',
  魔鬼: '#999999',
};

const OCTUPLE_VALUE_MAP = {
  SE: {
    firstLine:
      '帮助我们注意到物质世界丰富的细节，并被那些能立刻得到结果的体验所吸引。',
    secondLine:
      '使用Se，我们能注意到大量相关的事实经验，以及感受周遭环境中马上要发生的事实。',
    thirdLine:
      '我们可以感受到那些激动人心的身体冲动或本能，使我们自在的遵循这些冲动和本能，并享受当下的行动快感。当我们移动、触摸、感受我们周围的事物的时候，会感觉到融为一体。',
    fourLine:
      'Se还包含感受环境的暗示并执行，来看看现实情况下还能走多远，以获得我们想要的刺激。为此我们会压榨身体机能去寻找更多的碎片，来拼凑出整幅画卷，直到这幅画完成，或者被其他事物吸引。',
  },
  SI: {
    firstLine: '帮助我们存储和管理历史数据、信息，和与当前情况的对比。',
    secondLine:
      '使用Si，我们总是把眼前的经验和言语和先前的经验建立关系，我们记录它们的相似性或差异。 例如注意到一些口味不尽相同或比通常的咸，或者看到一个人谁想起另一个人。有时候相关的情感会伴随着我们的回忆一起出现。这种回忆是如此的强大，让我们有昨日重现之感。',
    thirdLine:
      '这个过程还包括回顾过去吸取历史，以及它们是如何产生的清晰画面，包含事后的教训和经验，这会让我们十分注重细节，得到目标。',
    fourLine:
      'Si还包含保护那些众所周知的、长存的规则，甚至当他们早已不可信的时候。这有助于维持文明和文化。',
  },
  NE: {
    firstLine:
      '帮助我们注意到隐含的意义并解释他们，常常喜欢从仅仅一个想法引发的大量可能的解释或者解释某个人行为背后真正的含义。',
    secondLine:
      '使用Ne，我们能立刻整合我们脑海中的不同观点、想法、信念、意义，使他们可能都是正确的。我们能享受头脑风暴和相信那些浮出水面的，用穿越前后联系的思维方式，富有想象力的考虑各种场景和可能性。',
    thirdLine:
      '这就像将主题和线索编织在一起一样。除非所有的线索出现或者找出想法间的相互关联，否则我们不知道应该怎么编织。因此那些策略或者概念是从此时此地的相互作用中显露，并非事先就存在。',
    fourLine: 'Ne也包括促进人们和形成临时的态势，通过危机领导散播改变气氛。',
  },
  NI: {
    firstLine:
      '帮助我们集中注意力关注我们的想象：事物未来如何发展，包括综合看上去自相矛盾或者矛盾的，这能将理解提高到新的水平。',
    secondLine:
      '使用Ni，我们能感觉到一个全新的、从来没想到过的认知出现在脑海。会突然叫到“ aha!”或者“就是这样”，然后从繁杂的事件关系中解脱出来。',
    thirdLine:
      '我们可能依靠聚焦的手段或者象征的方式去预测、启发、改变。我们可以发现我们能基于那些看不到的趋势和显示的迹象，想象出未来将如何展现。',
    fourLine:
      '这一过程包括弄懂复杂的概念或者思考系统或者象征的构想或者新颖的方式去理解普遍的事物。这也能导致创造卓越的想法或解决方式。',
  },
  TE: {
    firstLine:
      '帮助我们通过图、表格、图表、提纲等等来组织我们周围的环境和想法。包括组织和监控人的偶然的计划、日程和量化消耗，使他们具有效率和富有成果。',
    secondLine:
      '使用Te，我们可以用符合逻辑的事实来质疑某人的观点，或者对决定或者结论的得出合理的解释，常常试图建立一个思考的沙盘。',
    thirdLine:
      '在书面或者口头交流中，我们很容易的跟随某人的逻辑、顺序、组织，它也帮助我们注意到过错。',
    fourLine:
      '一般说来，它使我们规划我们的生活的各个方面，使我们能做完成目标所必须做的事情。',
  },
  TI: {
    firstLine:
      '帮助我们找到最恰当的词去清晰表达一个想法，简洁的、干净的、切中要害的。',
    secondLine:
      '使用Ti，我们能关注到事物内部基本的性质及内部的原理，注意到精微的区别，找到它原本的定义并命名。它也包括深层分析品种的亚类、规则的子原则、公理如何得到定理等。',
    thirdLine:
      '这个过程在行为上会表现出喜欢观察事物或者想法，分别指出它们是如何工作的。包括研究问题的不同面、观察概念的不一致等。',
    fourLine:
      '在如此做的时候，我们寻找到对系统最少影响或者损害的“杠杆点”。当我们注意到陈述和参照标准间逻辑不一致的时候，我们用Ti来评估陈述的可信度和准确性。',
  },
  FE: {
    firstLine:
      '帮助我们其他人保持（或者断开）联系，并常常通过温和（或者不悦）的表情和自我表露来显示。',
    secondLine:
      '使用Fe，我们能表达礼貌、周到、友好、体贴、恰当。也体现在开玩笑的时候和别人一起大笑，尝试让人们友好相处等。',
    thirdLine:
      '我们满足他人表达出的、甚至是没有表达出的要求和需要。我们询问他人的需要或者自我表露，促使他人更多的谈论自己。/n这常常引发交流，让人更加相互了解，也能让自己能更好的调节对他人的行为。',
    fourLine:
      '有时候，我们对负责和满足他人的的感情感觉到吃力，关键在于没有将我们的感情与他人的情感区分开来。',
  },
  FI: {
    firstLine: '帮助我们将有价值的、需要的、值得相信的筛选出来。',
    secondLine:
      '使用Fi，我们能不断的衡量情形的价值或者所有事情的重要性，像一个过滤器。我们可以知道别人作假或者不真诚，或者他们本质上是好的。',
    thirdLine:
      '当生活中的核心问题危及到价值时，我们想“有时候，一些事情只是不得不说”的时候，正是Fi正在运作。另一方面，大多数时候这一过程是在私下运作的，并通过行为来表达。',
    fourLine:
      'Fi还包含感受自我的情绪，它也像一个放大镜，让我们清晰的感觉到内心的细腻情感。',
  },
};

const OCTUPLE_VALUE_LIST_MAP = {
  SE: ['SE', 'SI'],
  SI: ['SE', 'SI'],
  NE: ['NE', 'NI'],
  NI: ['NE', 'NI'],
  TE: ['TE', 'TI'],
  TI: ['TE', 'TI'],
  FE: ['FE', 'FI'],
  FI: ['FE', 'FI'],
};

const SEARCH_OPTION = [
  { label: '全部', value: 0 },
  { label: '人物', value: 1 },
  { label: '作品', value: 2 },
];

const ITEM_SEARCH_OPTION = [
  { label: '全部', value: 0 },
  { label: '人物', value: 1 },
];

const WORK_SEARCH_OPTION = [
  { label: '全部', value: 0 },
  { label: '作品', value: 2 },
];

const PRODUCT_LIST = [
  {
    img: Octuple,
    title: '八维功能',
    subhead: '易懂入门科普',
  },
  {
    img: Test,
    title: '测MBTI',
    subhead: '荣格八维测试',
  },
  {
    img: Relation,
    title: '关系图鉴',
    subhead: 'MBTI × MBTI',
  },
  {
    img: Wiki,
    title: '性格类型',
    subhead: '八维解读 16P',
  },
  {
    img: Calculator,
    title: '八维转换器',
    subhead: '16P ⇄ 八维',
  },
];

const HOT_SEARCH_LIST = [
  {
    type: 'work',
    id: 4705,
    name: '甄嬛传',
  },
  {
    type: 'item',
    id: 79817,
    name: '五条悟',
  },
  // {
  //   type: 'mbti',
  //   id: 79817,
  //   name: 'ENTP',
  // },
  {
    type: 'work',
    id: '434',
    name: '英雄联盟',
  },
  {
    type: 'item',
    id: 377738,
    name: '余华',
  },
  {
    type: 'item',
    id: 376075,
    name: '莫言',
  },
  {
    type: 'item',
    id: 130943,
    name: '周杰伦',
  },
  {
    type: 'item',
    id: 45,
    name: '梅西',
  },
  {
    type: 'work',
    id: '134',
    name: '海贼王',
  },
];

const CLASSIFY_LIST = [
  {
    label: '科学',
    id: 21,
  },
  {
    label: '哲学',
    id: 28,
  },
  {
    label: '商人',
    id: 17,
  },
  {
    label: '历史',
    id: 22,
  },
  {
    label: '艺术',
    id: 16,
  },
  {
    label: '运动',
    id: 5,
  },
  {
    label: '流行',
    id: 1,
  },
  {
    label: '电影',
    id: 3,
  },
  {
    label: '电视剧',
    id: 2,
  },
  {
    label: '音乐',
    id: 14,
  },
  {
    label: '超级英雄',
    id: 27,
  },
  {
    label: 'ACG',
    id: 8,
  },
  {
    label: '游戏',
    id: 11,
  },
  {
    label: 'Kpop',
    id: 29,
  },
  {
    label: '卡通',
    id: 7,
  },
];

const PAGESIZE = 10;

export {
  CLASSIFY_LIST,
  HOT_SEARCH_LIST,
  ITEM_SEARCH_OPTION,
  MBTI_OCTUPLES,
  MBTI_OCTUPLE_LIST,
  MBTI_OCTUPLE_MAP,
  MBTI_ROLE_COLOR_MAP,
  MBTI_ROLE_LIST,
  OCTUPLE_VALUE_LIST_MAP,
  OCTUPLE_VALUE_MAP,
  PAGESIZE,
  PRODUCT_LIST,
  SEARCH_OPTION,
  WORK_SEARCH_OPTION,
};
