/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-05-03 03:08:17
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-05-03 03:23:16
 * @FilePath: /mbti-web/app/models/search.js
 * @Description:顶部搜索相关
 */
import { atomWithReset } from 'jotai/utils';

const nameAtom = atomWithReset(''); // 当前输入框内容
const openAtom = atomWithReset(false); // 是否展开下拉菜单
const tabKeyAtom = atomWithReset(0); // 当前tab选项
const tierAtom = atomWithReset(0); // 当前层级
const workListAtom = atomWithReset([]); // 作品列表
const workListCurrentAtom = atomWithReset(1); // 作品列表当前页
const workListTotalAtom = atomWithReset(0); // 作品列表总数
const itemListAtom = atomWithReset([]); // 人物列表
const itemListCurrentAtom = atomWithReset(1); // 人物列表当前页
const itemListTotalAtom = atomWithReset(0); // 人物列表总数
const towItemListAtom = atomWithReset([]); // 第二层人物列表
const towItemListCurrentAtom = atomWithReset(1); // 第二层人物列表当前页
const towItemListTotalAtom = atomWithReset(0); // 第二层人物列表总数
const iselWorkIdAtom = atomWithReset(0); // 选中的作品id

export {
  iselWorkIdAtom,
  itemListAtom,
  itemListCurrentAtom,
  itemListTotalAtom,
  nameAtom,
  openAtom,
  tabKeyAtom,
  tierAtom,
  towItemListAtom,
  towItemListCurrentAtom,
  towItemListTotalAtom,
  workListAtom,
  workListCurrentAtom,
  workListTotalAtom,
};
