/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-02-02 17:01:50
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-05-28 16:14:07
 * @FilePath: /mbti-web/app/service/index.js
 * @Description:
 */

const BASE_URL = 'https://api.wikimbti.com/api';

const request = async (url, method, params) => {
  const option = {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (method === 'GET') url = `${url}?${new URLSearchParams(params)}`; // fetch不会自动把params参数拼接成查询字符串
  if (method === 'POST') option['body'] = JSON.stringify(params);

  const response = await fetch(`${BASE_URL}${url}`, option);

  const { data } = await response.json();

  return data;
};

//  获取人物id列表
export const getPersonIdList = () => {
  return request(`/person/id?count=50000&time=${Date.now()}`, 'GET', {});
};

//  获取人物信息
export const getPedia = (params) => {
  return request('/iaxpyshu', 'GET', params);
};

// 获取人物详情
export const getDetail = (params) => {
  return request('/detail', 'POST', params);
};

//搜索人物
export const searchItem = (params) => {
  return request('/search/item', 'GET', params);
};

//搜索作品
export const searchWorks = (params) => {
  return request('/search/works', 'GET', params);
};

// 获取所有作品信息
export const getWorkList = () => {
  return request(`/api/search/works`, 'GET', {
    name: '',
    page: 1,
    limit: 10,
  });
};

// 首页分类搜索人物列表 or 作品内人物列表
export const getList = (params) => {
  return request('/list', 'GET', params);
};
